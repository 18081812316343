import { Flex, Text, Button, InputLeftElement, InputGroup, InputRightElement, Badge } from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { useEffect, useState } from "react";
import { usePixLimit } from "@/hooks/usePixLimit";
import { useForm } from "react-hook-form";
import { PixMiniIcon } from "@/styles/icons/PixMiniIcon";
import { formatCurrency, formatCurrencyNoBrSign, formatCurrencyToApi, showToast } from "@/utils";
import { Input, FormModal } from "@/components";
import { Tooltip } from "@/components/Tooltip";
import { MoonIcon } from "@/styles/icons/MoonIcon";
import { SunIcon } from "@/styles/icons/SunIcon";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { ArrowRightIcon } from "@/styles/icons/ArrowRightIcon";
import { useSettings } from "@/hooks";
import { AlertBanner } from "@/components/AlertBanner";

const Pix = () => {
    const [isPixLimitDisable, setIsPixLimitDisable] = useState<boolean>(false);
    const [editionMode, setEditionMode] = useState<boolean>(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [initialData, setInitialData] = useState([]);
    const { getPixLimit, updatePixLimit } = usePixLimit();
    const { getBankingAccount } = useSettings();
    const { 
      register,
      setValue,
      getValues,
      watch,
    } = useForm();

    const methods = [
      {
          title: "PIX",
          icon: <PixMiniIcon />,
          method: "pix",
      },
    ]
    const formatIconPeriods = {
        "DAY": <SunIcon />,
        "NIGHT": <MoonIcon />
    }
    const formatTextPeriods = {
        "DAY": "Diurno",
        "NIGHT": "Noturno"
    }
    const periods = ["DAY", "NIGHT"]
    function handleEditSection() {
        setEditionMode(!editionMode);
    }

    async function getInitialValues() {
        try {
            const response_banking = await getBankingAccount();
            if(response_banking?.data?.status === "INACTIVE" || !response_banking?.data) {
                setIsPixLimitDisable(true);
                return
            }
        } catch (err) {
            console.log(err);
            setIsPixLimitDisable(true);
        }
        try {
            const response = await getPixLimit();

            response?.data?.map(data => {
                setValue(`${methods[0].method}${data.period}Amount`, formatCurrencyNoBrSign({value: data.amount}))
                setValue(`${methods[0].method}${data.period}AccountId`, data.accountId)
                setValue(`${methods[0].method}${data.period}LimitId`, data.limitId)
                setValue(`${methods[0].method}${data.period}ScheduledAmount`, data.scheduledAmount)
            })
            
            setInitialData(response.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
      }
      async function onSubmit() {
        setIsSubmitting(true);
        const values = getValues();
        const formData = periods.map(period => {
            const value = {
                limitId: values[`${methods[0].method}${period}LimitId`],
                accountId: values[`${methods[0].method}${period}AccountId`],
                amount: formatCurrencyToApi(values[`${methods[0].method}${period}Amount`]),
            }
            return value
        })
        try {
            await updatePixLimit(formData[0]);
            await updatePixLimit(formData[1]);
            showToast({
                type: "success",
                message: "Limites alterado com sucesso.",
            });
        } catch (error) {
            error?.response?.data?.errors.map((error) => {
                showToast({
                  type: "error",
                  message: `${error?.code} - ${error?.description}`
                })
            });
        } finally {
            setEditionMode(false);
            setIsSaveModalOpen(false);
            setIsSubmitting(false);
            getInitialValues();
        }

      }
    
      useEffect(() => {
        getInitialValues();
      }, [])
    
    return (
        <>
            <Flex 
                w="100%" 
                flexDir="column" 
                padding="2rem 6rem"
                gap="3rem"
            >
                <Text
                    fontSize="1.6rem"
                    fontWeight="600"
                    color="black"
                    aria-label="Page header"
                >
                    Configurações Pix
                </Text>
                <Flex
                    w="100%"
                    align="center"
                    justify="space-between"
                >
                    <Text
                        fontSize="1.2rem"
                        fontWeight="600"
                        color="black"
                        aria-label="Subtitle"
                    >
                        Limite de transferências
                    </Text>
                    {!editionMode && (
                    <>
                        <Button
                            h="40px"
                            padding="8px 16px"
                            borderRadius="2px"
                            bgColor="#FFFFFF"
                            border="1px solid #D9D9D9"
                            onClick={handleEditSection}
                            disabled={isPixLimitDisable || 
                                watch(`pixDAYScheduledAmount`) !== 0 && watch(`pixDAYScheduledAmount`) !== null || 
                                watch(`pixNIGHTScheduledAmount`) !== 0 && watch(`pixNIGHTScheduledAmount`) !== null
                            }
                        >
                        <Text
                            color="#000000"
                            fontSize="16px"
                            fontWeight="400"
                        >
                            Editar
                        </Text>
                        </Button>
                    </>
                    )}

                    {editionMode && (
                        <Flex gap="12px">
                            <Button
                                h="40px"
                                padding="8px 16px"
                                borderRadius="2px"
                                bgColor="#FFFFFF"
                                border="1px solid #D9D9D9"
                                transition="filter .2s"
                                _hover={{
                                    transition: "filter .2s",
                                    filter: "brightness(.9)",
                                }}
                                onClick={() => setEditionMode(false)}
                            >
                                <Text
                                    color="#000000"
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Cancelar
                                </Text>
                            </Button>
                            <Button
                                type="button"
                                h="40px"
                                padding="8px 16px"
                                borderRadius="2px"
                                bgColor="#1890FF"
                                border="1px solid #1890FF"
                                transition="filter .2s"
                                _hover={{
                                    transition: "filter .2s",
                                    filter: "brightness(.9)",
                                }}
                                onClick={() => setIsSaveModalOpen(true)}
                                disabled={watch("pixDAYAmount") === "" || watch("pixNIGHTAmount") === ""}
                            >
                                <Text
                                    color="#FFFFFF"
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Salvar
                                </Text>
                            </Button>
                        </Flex>
                    )}

                </Flex>
            <FormModal
                isOpen={isSaveModalOpen}
                onClose={() => setIsSaveModalOpen(false)}
                handleConfirmModal={onSubmit}
                title="Confirmar alteração de limites"
                isSubmitting={isSubmitting}
                confirmationButtonText="Confirmar"
            >
                <Flex 
                    w="100%"
                    flexDir="column"
                    gap=".5rem"
                >
                    {
                    (watch(`pixDAYScheduledAmount`) !== 0 && watch(`pixDAYScheduledAmount`) !== null || 
                    watch(`pixNIGHTScheduledAmount`) !== 0 && watch(`pixNIGHTScheduledAmount`) !== null) && (
                        <Text
                            fontSize=".8rem"
                            textAlign="center"
                            backgroundColor="yellow.100"
                            borderRadius=".5rem"
                            p=".5rem"
                        >
                           Voce já tem um pedido de alteração em andamento, ao confirmar irá atualizar o valor agendado anteriormente.
                        </Text>
                    )}

                    <Text
                        fontSize=".8rem"
                    >
                        Aumentos de limite levam 24h para serem efetivados.
                        Diminuições de limites são imediatos.
                    </Text>
                    {methods?.map(method => (
                        <Flex 
                            w="100%"
                            border="1px solid"
                            borderRadius="1rem"
                            borderColor="#E6E9F0"
                            px=".4rem"
                            py=".8rem"
                            flexDir="column"
                            gap=".5rem"
                        >
                            <Flex
                                gap=".5rem"
                                w="120px"
                                alignItems="center"
                            >
                                {method.icon}
                                <Text
                                    fontWeight={600}
                                >
                                    {method.title}
                                </Text>
                            </Flex>
                            {initialData.map(data => (
                                <Flex
                                    gap=".5rem"
                                    alignItems="center"
                                >
                                    {formatIconPeriods[data.period]}
                                    <Text
                                        w="60px"
                                        fontSize=".8rem"
                                    >
                                        {formatTextPeriods[data.period]}
                                    </Text>
                                    <Text
                                        fontSize=".8rem"
                                        textDecoration={data.amount !== formatCurrencyToApi(watch(`${methods[0].method}${data.period}Amount`)) ? "line-through" : "none"}
                                    >
                                        {formatCurrency({value: data.amount ?? 0})} / dia
                                    </Text>
                                    {data.amount !== formatCurrencyToApi(watch(`${methods[0].method}${data.period}Amount`)) && (
                                        <>
                                            <ArrowRightIcon />
                                            <Text
                                                fontSize=".8rem"
                                            >
                                                {formatCurrency({value: formatCurrencyToApi(watch(`${methods[0].method}${data.period}Amount`))})}
                                            </Text>
                                            {data.amount < formatCurrencyToApi(watch(`${methods[0].method}${data.period}Amount`)) && (
                                                <Badge
                                                    variant="lightGray"
                                                >
                                                    24 horas
                                                </Badge>
                                            )}
                                        </>
                                    )}
                                </Flex>
                            ))}
                        </Flex>
                    ))}
                </Flex>
            </FormModal>
            {isLoading && (
                <Flex
                    w="100%"
                    alignItems="center"
                    justify="center"
                >
                    <LoadingSpinner />
                </Flex>
            )}
            {!isLoading && isPixLimitDisable && (
                <AlertBanner
                    iconTitle={<></>}
                    title="Conta não configurada."
                    description="Para ter acesso ao ajuste de limites, é preciso realizar a configuração de sua conta."
                />
            )}
            {!isLoading && !isPixLimitDisable && methods?.map(method => (
                    <Flex 
                        w="100%"
                        border="1px solid"
                        borderRadius="1rem"
                        borderColor="#E6E9F0"
                        px="2rem"
                        py="1.3rem"
                    >
                        <Flex
                            align="center"
                            gap=".5rem"
                            w="120px"
                        >
                            {method.icon}
                            <Text
                                fontWeight={600}
                            >
                                {method.title}
                            </Text>
                        </Flex>
                        <Flex
                            w="100%"
                            justify="space-between"
                            gap="1rem"
                        >
                            <Flex
                                align="center"
                                gap=".5rem"
                                w="100%"
                            >
                                <InputGroup 
                                    maxW="190px"
                                    position="relative"
                                >
                                    <Flex
                                        align="center"
                                        position="absolute"
                                        top="-45px"
                                        gap=".5rem"
                                        w="380px"
                                    >
                                        <SunIcon />
                                        <Text
                                            fontWeight={600}
                                        >
                                            Limite de transferência diurno
                                        </Text>
                                        <Tooltip label="6h00 às 19h59min59s">
                                        </Tooltip>
                                    </Flex>
                                    <InputLeftElement
                                        color="#A5AEC0"
                                    >
                                        R$
                                    </InputLeftElement>
                                    <Input
                                        name={`${method.method}DAYAmount`}
                                        register={{...register(`${method.method}DAYAmount`)}}
                                        type="text"
                                        borderRadius=".5rem"
                                        isDisabled={!editionMode}
                                        border={!editionMode ? "" : "1px solid"}
                                        _disabled={{
                                            color: "black"
                                        }}
                                        px="2rem"
                                        mask="currency(noBrSign)"
                                    />
                                    <InputRightElement
                                        color="#A5AEC0"
                                        marginRight=".5rem"
                                    >
                                        /dia
                                    </InputRightElement>
                                </InputGroup>
                                {watch(`${method.method}DAYScheduledAmount`) !== 0 && watch(`${method.method}DAYScheduledAmount`) !== null && (
                                    <Badge
                                        variant="lightGray"
                                    >
                                        <Flex
                                            align="center"
                                            gap=".5rem"
                                            fontSize=".8rem"
                                        >
                                            Alteração em processamento 
                                            <Tooltip label={`Aumentos de limite levam 24h para serem efetivados. Valor em processamento: ${formatCurrency({value: watch(`${method.method}DAYScheduledAmount`)})}`}>
                                            </Tooltip>
                                        </Flex>
                                    </Badge>
                                )}
                            </Flex>
                            <Flex
                                align="center"
                                gap=".5rem"
                                w="100%"
                            >
                                <InputGroup
                                    maxW="190px"
                                    position="relative"
                                >
                                    <Flex
                                        align="center"
                                        position="absolute"
                                        top="-45px"
                                        gap=".5rem"
                                        w="380px"
                                    >
                                        <MoonIcon />
                                        <Text
                                            fontWeight={600}
                                        >
                                            Limite de transferência noturno
                                        </Text>
                                        <Tooltip label="20h00 às 5h59min59s">
                                        </Tooltip>
                                    </Flex>
                                    <InputLeftElement
                                        color="#A5AEC0"
                                    >
                                        R$
                                    </InputLeftElement>
                                    <Input
                                        name={`${method.method}NightAmount`}
                                        register={{...register(`${method.method}NIGHTAmount`)}}
                                        type="text"
                                        borderRadius=".5rem"
                                        isDisabled={!editionMode}
                                        border={!editionMode ? "" : "1px solid"}
                                        _disabled={{
                                            color: "black"
                                        }}
                                        px="2rem"
                                        mask="currency(noBrSign)"
                                    />
                                    <InputRightElement
                                        color="#A5AEC0"
                                        marginRight=".5rem"
                                    >
                                        /dia
                                    </InputRightElement>
                                </InputGroup>
                                {watch(`${method.method}NIGHTScheduledAmount`) !== 0 && watch(`${method.method}NIGHTScheduledAmount`) !== null && (
                                    <Badge
                                        variant="lightGray"
                                    >
                                        <Flex
                                            align="center"
                                            gap=".5rem"
                                            fontSize=".8rem"
                                        >
                                            Alteração em processamento 
                                            <Tooltip label={`Aumentos de limite levam 24h para serem efetivados. Valor em processamento: ${formatCurrency({value: watch(`${method.method}NIGHTScheduledAmount`)})}`}>
                                            </Tooltip>
                                        </Flex>
                                    </Badge>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                ))
            }
            </Flex>
        </>
    );
};
export default WithAuth(Pix);