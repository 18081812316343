import { Button, Flex, Heading, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { usePix } from "@/hooks/usePix";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CopyIcon, DocumentIcon, EmailIcon, PhoneIcon, RandomIcon } from "@/styles/icons";
import { BsThreeDots } from "react-icons/bs"
import { TrashIcon2 } from "@/styles/icons/TrashIcon2";
import { useSettings } from "@/hooks";
import { clearMask, copyTextToClipboard, showToast } from "@/utils";
import { FormModal, Input } from "@/components";
import { ListSelector } from "@/components/Forms/Selector";
import { useForm } from "react-hook-form";
import { PixKeyBankingTypes } from "@/models";

const PixKeysComponent = () => {
    const { getPixKeys, deletePixKey, createPixKey } = usePix();
    const { getBankingAccount } = useSettings();
    const userDocument = JSON.parse(localStorage.getItem("barte-user-document"));
    const [initialData, setInitialData] = useState([]);
    const [isNewPixKeyFormOpen, setIsNewPixKeyFormOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [accountId, setAccountId] = useState("");
    const {
        register,
        formState: { errors },
        watch,
        getValues,
        setValue,
      } = useForm();
    async function getInitialData() {
        try {
            setIsLoadingData(true);
            const banking_response = await getBankingAccount();
            const response = await getPixKeys(banking_response.data.debitAccountId);
            setAccountId(banking_response.data.debitAccountId);
            setInitialData(response.data);
        } catch (error) {
            error?.response?.data?.errors.map((error) => {
                showToast({
                  type: "error",
                  message: `${error?.code} - ${error?.description}`
                })
            });
        } finally {
            setIsLoadingData(false);
        }
    }
    const deleteKey = async(id: string, keyType: string) => {
        try {
            await deletePixKey(id, keyType);
            getInitialData();
            showToast({
                type: "success",
                message: "Chave pix deletada com sucesso.",
            });
        } catch (error) {
            error?.response?.data?.errors.map((error) => {
                showToast({
                  type: "error",
                  message: `${error?.code} - ${error?.description}`
                })
            });
        }
    }
    const getKeyType = (type?: string) => {
        const KeyType = {
          email: "Email",
          phone: "Celular",
          evp: "Aleatória",
          cnpj: "CNPJ",
        };
        return KeyType[type] || type;
    };
    const getKeyTypeIcon = (type?: string) => {
        const KeyType = {
          email: <EmailIcon size={24} />,
          phone: <PhoneIcon size={24} />,
          evp: <RandomIcon size={24} />,
          cnpj: <DocumentIcon size={24} />,
        };
        return KeyType[type] || type;
    };

    const columns: ColumnsType<any> = [
        {
          key: "key",
          title: "",
          dataIndex: "key",
          render: (text, record) => (
            <Flex
                w="100%"
                align="center"
                gap="1rem"
            >
                {getKeyTypeIcon(record?.keyType)}
                <Flex
                    flexDir="column"
                >
                    <Text
                        fontSize=".8rem"
                        color="newGray.400"
                    >
                        {getKeyType(record?.keyType)}
                    </Text>
                    <Text
                        fontSize="1rem"
                    >
                        {record?.key}
                    </Text>
                </Flex>
            </Flex>
          ),
        },
        {
            key: "buttonOptions",
            title: "",
            render: (text, record) => (
                <Popover>
                    <PopoverTrigger>
                        <Flex
                            h="40px"
                            w="40px"
                            align="center"
                            justify="center"
                            borderRadius="8px"
                            _hover={{
                                cursor: "pointer"
                            }}
                        >
                            <BsThreeDots />
                        </Flex>
                    </PopoverTrigger>
                    <PopoverContent w="220px">
                        <PopoverBody>
                            <Flex
                                w="100%"
                                flexDir="column"
                                gap=".5rem"
                            >
                                <Button
                                    borderRadius="4px"
                                    bgColor="transparent"
                                    onClick={() => {
                                        copyTextToClipboard(record?.key);
                                    }}
                                >
                                    <Flex
                                        align="center"
                                        gap=".5rem"
                                    >
                                        <CopyIcon />
                                        <Text
                                            fontWeight={400}
                                        >
                                            Copiar chave
                                        </Text>
                                    </Flex>
                                </Button>
                                <Button
                                    borderRadius="4px"
                                    bgColor="transparent"
                                    onClick={() => deleteKey(record?.key, record?.keyType)}
                                >
                                    <Flex
                                        align="center"
                                        gap=".5rem"
                                    >
                                        <TrashIcon2 
                                            color="#FF4D4F"
                                        />
                                        <Text
                                            color="#FF4D4F"
                                            fontWeight={400}
                                        >
                                            Excluir chave
                                        </Text>
                                    </Flex>
                                </Button>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            ),
          },
    ];

    const submitForm = async() => {
        const values = getValues();
        const formData = {
            accountId: accountId,
            key: clearMask(values?.pixKey),
            keyType: values?.pixKeyType,
        }
        try {
            setIsSubmitting(true);
            await createPixKey(formData);
            showToast({
                type: "success",
                message: "Chave pix cadastrada com sucesso.",
            });
            getInitialData();
        } catch(error) {
            error?.response?.data?.errors.map((error) => {
                showToast({
                  type: "error",
                  message: `${error?.code} - ${error?.description}`
                })
            });
        } finally {
            setIsNewPixKeyFormOpen(false);
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        getInitialData();
    }, [])
    useEffect(() => {
        if(watch("pixKeyType") === "cnpj") {
            setValue("pixKey", `${userDocument}`);
        } else {
            setValue("pixKey", "");
        }
    }, [watch("pixKeyType")])
    return (
        <>
            <FormModal
                isOpen={isNewPixKeyFormOpen}
                onClose={() => setIsNewPixKeyFormOpen(false)}
                handleConfirmModal={submitForm}
                title="Cadastrar nova Chave Pix"
                isSubmitting={isSubmitting}
                confirmationButtonText="Cadastrar"
                disableWhenIsFormEmpty={watch("pixKeyType") !== "evp" && (watch("pixKey") === "" || watch("pixKey") === undefined)}
            >
                <Flex 
                    w="100%"
                    flexDir="column"
                    gap="1rem"
                >
                    <ListSelector
                        label="Tipo de chave"
                        placeholder="Selecione"
                        options={PixKeyBankingTypes}
                        name="pixKeyType"
                        register={{ ...register("pixKeyType") }}
                        errors={errors?.pixKeyType}
                    />
                    {watch("pixKeyType") !== "evp" && watch("pixKeyType") !== "" && watch("pixKeyType") !== undefined && (
                        <Input
                            label={getKeyType(watch("pixKeyType"))}
                            type="text"
                            name="pixKey"
                            register={{ ...register("pixKey") }}
                            errors={errors?.pixKey}
                            isDisabled={watch("pixKeyType") === "cnpj"}
                        />
                    )}
                </Flex>
            </FormModal>
            <Flex
                w="100%"
                flexDir="column"
                gap="1rem"
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Heading fontSize="1.2rem">Chaves Pix</Heading>
                    <Button 
                        variant="primary"
                        onClick={() => setIsNewPixKeyFormOpen(true)}
                        isDisabled={initialData?.length === 20}
                    >
                        Nova Chave Pix
                    </Button>
                </Flex>
                <Table<any>
                    columns={columns}
                    dataSource={initialData}
                    rowKey={(record) => record.id}
                    locale={{ emptyText: "Sem dados de chaves pix." }}
                    loading={isLoadingData}
                />
            </Flex>
        </>
    );
}

export { PixKeysComponent };
